<template>
  <stripe-checkout
    ref="checkoutRef"
    :pk="publishableKey"
    :items="items"
    :successUrl="successUrl"
    :cancelUrl="cancelUrl"
  >
    <template slot="checkout-button">
      <button @click="checkout">Pagar!</button>
    </template>
  </stripe-checkout>
</template>
 
<script>
import { StripeCheckout } from "vue-stripe-checkout";
export default {
  components: {
    StripeCheckout
  },
  data: () => ({
    loading: false,
    items: [
      {
        sku: "sku_FdQKocNoVzznpJ",
        quantity: 1
      }
    ],
    publishableKey: "pk_live_NsqMZ8Nu7XgI7aQB98Zc1ouJ00y3e3coDA",
    successUrl: "https://www.google.com/",
    cancelUrl: "https://gitlab.com/"
  }),
  methods: {
    checkout() {
      this.$refs.checkoutRef.redirectToCheckout();
    }
  }
};
</script> 