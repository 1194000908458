<template>
  <div class="main-container">
    <Header />

    <Stripe />

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Stripe from "@/components/Stripe";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  data() {
    return {};
  },
  components: {
    Header,
    Breadcrumb,
    Stripe,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  }
};
</script>
